import { defineStore } from "pinia";
import { GET_USER_STATS } from "~/graphql/stats";

export const useStatsStore = defineStore("StatsStore", {
  state: () => ({
    offers: 0,
    requests: 0,
    orders: 0,
  }),

  actions: {
    async getStats() {
      try {
        console.log("Fetching Stats 📊 ");

        const { onResult } = useQuery(GET_USER_STATS);
        onResult((queryResult) => {
          let offers = [...queryResult?.data.OrderStats].filter(
            (item) => item.status === "OPEN"
          ).length;

          let requests = [...queryResult?.data.OrderStats].filter(
            (item) =>
              item.status === "OFFERED" &&
              item.offers.some((offer) => offer.status === "OFFERED")
          ).length;

          let orders = [...queryResult?.data.OrderStats].filter(
            (item) => item.status === "ORDERED" || item.status === "PAID"
          ).length;

          this.offers = offers;
          this.requests = requests;
          this.orders = orders;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
});
